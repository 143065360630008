import React, { useState } from "react";
import styled from "styled-components";
import { FiHome, FiLogIn, FiMenu } from "react-icons/fi";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { smallMenuHandler } from "../../features/user/userSlise";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../images/Ellipse 1.png";

const MENU_HEIGHT = "60px"; // Высота меню

const SmallMenu = ({ isVisible }) => {
  const { smallMenuPosition, user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [active, setActive] = useState("main");

  const mainHandler = () => {
    dispatch(smallMenuHandler("Главная"));
    navigate("/");
  };

  const catalogHandler = () => {
    dispatch(smallMenuHandler("Каталог"));
    navigate("/catalog");
  };

  const loginHandler = () => {
    dispatch(smallMenuHandler("Вход"));
    navigate("/login");
  };

  return (
    <Wrapper isVisible={isVisible}>
      <div className="link" onClick={mainHandler}>
        <FiHome className={smallMenuPosition === "Главная" ? "active" : null} />
        <p>Главная</p>
      </div>
      <div className="link" onClick={catalogHandler}>
        <FiMenu className={smallMenuPosition === "Каталог" ? "active" : null} />
        <p>Каталог</p>
      </div>
      {!user && (
        <div className="link" onClick={loginHandler}>
          <FiLogIn className={active === "login" ? "active" : null} />
          <p>Войти</p>
        </div>
      )}
      {user && (
        <div className="link">
          <img src={avatar} alt="avatar" />
          <p>Профиль</p>
        </div>
      )}
      {user && (
        <div className="plus">
          <BsFillPlusCircleFill />
        </div>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  /* bottom: 0; */
  display: flex;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  height: 60px;
  padding: 11px 8px 8px 8px;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0px -4.8px 9.6px 0px rgba(0, 0, 0, 0.15);
  transition: bottom 0.3s;
  bottom: ${(props) => (props.isVisible ? "0" : `-${MENU_HEIGHT}`)};
  z-index: 2;
  font-family: "Cera Pro Medium", sans-serif;

  .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover {
      svg {
        color: var(--blue-1);
      }
      p {
        color: var(--blue-1);
      }
    }
    p {
      margin: 0;
      font-size: 12px;
    }
    svg {
      font-size: 24px;
      cursor: pointer;
    }
    .active {
      color: var(--blue-1);
    }
  }
  .plus {
    cursor: pointer;
    svg {
      width: 45px;
      height: 45px;
      color: var(--blue-1);
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: none;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default SmallMenu;
