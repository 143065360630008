import React, { useState } from "react";

import styled from "styled-components";

const AboutSave = () => {
  const [tag, setTag] = useState("Покупателю");

  return (
    <Wrapper>
      <p className="save-order">Безопасная сделка в несколько простых шагов</p>
      <div className="actions">
        <button
          onClick={() => setTag("Покупателю")}
          className={
            tag === "Покупателю" ? "offer-tag offer-tag-active" : "offer-tag"
          }
        >
          Покупателю
        </button>
        <button
          onClick={() => setTag("Продавцу")}
          className={
            tag === "Продавцу" ? "offer-tag offer-tag-active" : "offer-tag"
          }
        >
          Продавцу
        </button>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #eef0f3;
  background: #fff;
  /* padding: 20px 0px; */
  padding: 15px 10px 0px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  /* border: 1px solid gray; */
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 15px 0;
  }
  .save-order {
    font-size: 20px;
  }
  .offer-tag {
    display: flex;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    height: 50px;
    border: none;
    background: transparent;
    font-size: 16px;
    word-wrap: nowrap;
    white-space: nowrap;
    font-family: "Cera Pro Medium", sans-serif;

    cursor: pointer;
    :hover {
      background: var(--green-1);
      transition: 0.5s;
    }
  }
  .offer-tag-active {
    background: var(--green-1);
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 23%;
    padding: 50px 40px;
    border-radius: 0;
    border-bottom-left-radius: 30px;
    height: 443px;
    .save-order {
      font-size: 25px;
    }
    .actions {
      flex-direction: column;
      justify-content: center;
      button {
        margin-top: 10px;
      }
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AboutSave;
