import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  registerUserThunk,
  loginUserThunk,
  getCatalogThunk,
  remindPassThunk,
} from "./thunk";
import toast from "react-hot-toast";
import {
  addTokenToLocalStorage,
  addUserToLocalStorage,
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
  removeTokenFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../utils/localStorage";

const initialState = {
  isLoading: false,
  user: getUserFromLocalStorage(),
  token: getTokenFromLocalStorage(),
  isSidebarOpen: false,
  submenuLocation: [],
  pageId: "",
  isCatalogBig: false,
  categories: [],
  smallMenuPosition: "Главная",
  isRegisterBig: false,
  isErrorLogin: false,
  remind_ok: false,
  errorRemind: "",
};

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (user, thunkAPI) => {
    return registerUserThunk("/auth/signup", user, thunkAPI);
  }
);

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    return loginUserThunk(`/auth/login/`, user, thunkAPI);
  }
);

export const getCatalog = createAsyncThunk(
  "user/getCatalog",
  async (info, thunkAPI) => {
    return getCatalogThunk(`/auth/getCatalog/`, info, thunkAPI);
  }
);

export const remindPass = createAsyncThunk(
  "user/remindPass",
  async (info, thunkAPI) => {
    console.log(info);
    return remindPassThunk(`/auth/remindPass/`, info, thunkAPI);
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logOutUser: (state) => {
      state.user = null;
      state.token = "";
      removeUserFromLocalStorage();
      removeTokenFromLocalStorage();
    },
    sidebarCloseHandler: (state) => {
      state.isSidebarOpen = false;
    },
    sidebarOpenHandler: (state) => {
      state.isSidebarOpen = true;
    },
    pageIdHandler: (state, { payload }) => {
      state.pageId = payload;
    },
    catalogBigHandler: (state) => {
      state.isCatalogBig = !state.isCatalogBig;
    },
    smallMenuHandler: (state, { payload }) => {
      state.smallMenuPosition = payload;
    },
    registerBigHandler: (state, { payload }) => {
      state.isRegisterBig = payload;
      state.isErrorLogin = false;
    },
    remind_okHandler: (state, { payload }) => {
      state.remind_ok = false;
    },
    errorRemindHandler: (state, { payload }) => {
      state.errorRemind = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.isLoading = true;
    });

    // registers
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      const { user, token } = payload;
      state.isLoading = false;
      state.user = user;
      state.token = token;
      addUserToLocalStorage(user);
      addTokenToLocalStorage(token);
      state.isRegisterBig = false;
      // toast.success(`Привет ${user.name} !`);
    });
    builder.addCase(registerUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // login
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      const { user, token } = payload;
      state.isLoading = false;
      state.user = user;
      state.token = token;
      addUserToLocalStorage(user);
      addTokenToLocalStorage(token);
      // toast.success(`Добро пожаловать  ${user.name} !`);
      state.isRegisterBig = false;
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
      state.isErrorLogin = true;
    });

    // getCatalog
    builder.addCase(getCatalog.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCatalog.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.categories = payload;
    });
    builder.addCase(getCatalog.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    });

    // remindPass
    builder.addCase(remindPass.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(remindPass.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.remind_ok = true;
      state.isRegisterBig = false;
    });
    builder.addCase(remindPass.rejected, (state, { payload }) => {
      state.isLoading = false;
      // toast.error(payload);
      state.errorRemind = payload;
    });
  },
});

export const {
  logOutUser,
  sidebarCloseHandler,
  sidebarOpenHandler,
  pageIdHandler,
  catalogBigHandler,
  smallMenuHandler,
  registerBigHandler,
  remind_okHandler,
  errorRemindHandler,
} = userSlice.actions;
export default userSlice.reducer;
