import React from "react";
import styled from "styled-components";
import avatar from "../../images/Ellipse 1.png";
import { FiPaperclip, FiSend } from "react-icons/fi";

const StarSVG = () => (
  <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
    <path
      d="M4.84216 0.440796L5.8121 3.42597L8.9509 3.42597L6.41156 5.27091L7.3815 8.25609L4.84216 6.41115L2.30282 8.25609L3.27276 5.27091L0.733418 3.42597L3.87222 3.42597L4.84216 0.440796Z"
      fill="#1766FF"
    />
  </svg>
);
const StarSVG3 = () => (
  <svg width="14" height="14" viewBox="0 0 9 9" fill="none">
    <path
      d="M4.84216 0.440796L5.8121 3.42597L8.9509 3.42597L6.41156 5.27091L7.3815 8.25609L4.84216 6.41115L2.30282 8.25609L3.27276 5.27091L0.733418 3.42597L3.87222 3.42597L4.84216 0.440796Z"
      fill="#1766FF"
    />
  </svg>
);

const AboutMessage = () => {
  return (
    <Wrapper>
      <div className="window">
        <div className="window-panel">
          <div className="window-panel-content">
            <div className="avatar">
              <img src={avatar} alt="avatar" />
            </div>
            <div className="avatar-info">
              <div className="line1">
                <div className="stars">
                  <div className="star2">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <StarSVG key={index} />
                    ))}
                  </div>
                  <div className="star3">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <StarSVG3 key={index} />
                    ))}
                  </div>
                  <p>5000 отзывов</p>
                </div>
              </div>
              <div className="line2">Продавец</div>
            </div>
            <div className="dots">...</div>
          </div>
        </div>
        <div className="cloud">
          <svg width="61" height="46" viewBox="0 0 61 46" fill="none">
            <g opacity="0.5">
              <path
                d="M46.0121 17.7448H42.7964C41.8415 14.0559 39.8685 10.7081 37.1016 8.08185C34.3347 5.4556 30.8848 3.65618 27.1438 2.88807C23.4029 2.11995 19.521 2.41394 15.9392 3.73663C12.3575 5.05932 9.21942 7.35766 6.88169 10.3705C4.54395 13.3834 3.10027 16.9899 2.71469 20.7803C2.3291 24.5707 3.01707 28.393 4.70043 31.8129C5.52551 33.4891 2.30176 43.5116 2.30176 43.5116C2.30176 43.5116 10.5843 39.1 12.2374 40.1266C15.4798 42.14 19.2234 43.2059 23.0428 43.203H46.0121C49.3964 43.203 52.6421 41.8619 55.0352 39.4748C57.4283 37.0876 58.7728 33.8499 58.7728 30.4739C58.7728 27.0979 57.4283 23.8602 55.0352 21.4731C52.6421 19.0859 49.3964 17.7448 46.0121 17.7448Z"
                stroke="#74A4FB"
                strokeWidth="3.08585"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="21.7426" cy="26.5394" r="1.85151" fill="#74A4FB" />
              <circle cx="28.5314" cy="26.5394" r="1.85151" fill="#74A4FB" />
              <circle cx="35.3207" cy="26.5394" r="1.85151" fill="#74A4FB" />
            </g>
          </svg>
        </div>
        <div className="input">
          <div className="area">
            <div className="input-el1">
              <FiPaperclip />
            </div>
            <div className="input-el2">Привет!</div>
            <div className="input-el3">
              <FiSend />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  background: var(--blue-1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 35px 15px 35px;
  .window {
    width: 266px;
    height: 266px;
    border-radius: 18px;
    border-radius: 18px;
    background: var(--blue-2);
    display: flex;
    flex-direction: column;
  }
  .window-panel {
    height: 66px;
    width: 266px;
    display: flex;
    align-items: center;
    background: var(--green-1);
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    position: relative;
  }
  .cloud {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .window-panel-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px;
    .avatar {
      img {
        border-radius: 50%;
      }
    }
  }
  .line1 {
    .stars {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        font-size: 9px;
        opacity: 0.5;
        margin-left: 5px;
      }
    }
  }
  .line2 {
    font-size: 12px;
  }
  .dots {
    position: absolute;
    align-items: flex-start;
    right: 15px;
    top: -10px;
    color: white;
    font-size: 25px;
  }
  .star3 {
    display: none;
  }
  .input {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    .area {
      width: 85%;
      height: 30px;
      background: white;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input-el1 {
        margin-left: 10px;
        color: var(--gray-1);
      }
      .input-el2 {
        font-size: 11px;
        color: var(--gray-1);
        width: 60%;
        text-align: start;
      }
      .input-el3 {
        margin-left: 10px;
        color: var(--blue-1);
        margin-right: 10px;
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 32%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    svg {
      font-size: 14px;
    }
    /* padding: 69px 0px 77px 69px; */

    /* padding-left: 30px; */
    .window {
      width: 320px;
      clip-path: inset(0px 30px 0px 0px);
      position: absolute;
      right: -30px;
    }
    .window-panel {
      width: 300px;
    }
    .line1 {
      .stars {
        p {
          font-size: 14px;
        }
      }
    }
    .line2 {
      font-size: 18px;
    }
    .avatar {
      img {
        width: 42px;
      }
    }
    .dots {
      position: absolute;
      align-items: flex-start;
      right: 2px;
      top: -25px;
      font-size: 40px;
    }
    .star2 {
      display: none;
    }
    .star3 {
      display: block;
    }
    .cloud {
      svg {
        scale: 1.5;
      }
      /* clip-path: inset(0px 30px 0px 0px); */
    }
    .input {
      margin-bottom: 15px;
      .area {
        width: 85%;
        height: 40px;
        .input-el1 {
          margin-left: 15px;
          color: var(--gray-1);
          svg {
            font-size: 18px;
          }
        }
        .input-el2 {
          font-size: 16px;
          color: var(--gray-1);
          width: 60%;
          text-align: start;
        }
        .input-el3 {
          margin-left: 10px;
          color: var(--blue-1);
          margin-right: 10px;
          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AboutMessage;
