import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import CollectionFooter from "./CollectionFooter";

const FooterLine1 = () => {
  const { categories } = useSelector((store) => store.user);
  const rootCategories = _.filter(categories, (c) => c.parentId === null);

  return (
    <Wrapper>
      {rootCategories.map((category) => (
        <CollectionFooter
          key={category._id}
          category={category}
          categories={categories}
          level={0}
        />
      ))}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 980px;
    flex-direction: row;
    align-items: start;
    flex-wrap: wrap;
  }
  @media (min-width: 1200px) {
    width: 1180px;
  }
  @media (min-width: 1400px) {
  }
`;
export default FooterLine1;
