import React, { useState } from "react";
import styled from "styled-components";

const offers_tag = [
  { id: 1, name: "Instagram" },
  { id: 2, name: "Litres" },
  { id: 3, name: "AppStore" },
  { id: 4, name: "В контакте" },
  { id: 5, name: "Word of tanks" },
];
const OfferHeader = () => {
  const [tag, setTag] = useState("Instagram");
  return (
    <Wrapper>
      <p className="head-text">Популярные предложения</p>
      <div className="offers-tag">
        {offers_tag.map((o) => (
          <button
            key={o.id}
            className={
              tag === o.name ? "offer-tag offer-tag-active" : "offer-tag"
            }
            onClick={() => setTag(o.name)}
          >
            {o.name}
          </button>
        ))}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: "Cera Pro Medium", sans-serif;

  .head-text {
    font-size: 24px;
    color: black;
    font-family: "Cera Pro Medium", sans-serif;
  }
  .offers-tag {
    display: flex;
    max-width: 95%;
    overflow-x: auto;
    margin-top: 20px;
    font-family: "Cera Pro Medium", sans-serif;

    &::-webkit-scrollbar {
      display: none; // Скрывает скроллбар для Webkit-браузеров (например, Chrome и Safari)
    }
    -ms-overflow-style: none; // Скрывает скроллбар в IE и Edge
    scrollbar-width: none; // Скрывает скроллбар в Firefox
  }
  .offer-tag {
    display: flex;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    height: 50px;
    border: none;
    background: transparent;
    font-size: 16px;
    word-wrap: nowrap;
    white-space: nowrap;
    font-family: "Cera Pro Medium", sans-serif;

    cursor: pointer;
    :hover {
      background: var(--green-1);
      transition: 0.5s;
    }
  }
  .offer-tag-active {
    background: var(--green-1);
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .head-text {
      font-size: 24px;
    }
    .offers-tag {
      max-width: 50%;
      margin-top: 0px;
    }
  }
  @media (min-width: 1200px) {
    .head-text {
      font-size: 40px;
    }
  }
  @media (min-width: 1400px) {
  }
`;
export default OfferHeader;
