import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import InputRegister from "./InputRegister";
import { useSelector, useDispatch } from "react-redux";
import ButtonBlue from "../../components-special/ButtonBlue";
import { remindPass, errorRemindHandler } from "../../features/user/userSlise";

const initialState = {
  email: "",
};
const RemindPass = () => {
  const { errorRemind } = useSelector((store) => store.user);

  const dispatch = useDispatch();

  const [values, setValues] = useState(initialState);
  const [isError, setIsError] = useState(false);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(errorRemindHandler());

    const { email } = values;
    if (!email) {
      //   toast.error("Введите все значения");
      setIsError(true);
      return;
    }
    dispatch(remindPass({ remind_email: email }));
  };

  return (
    <Wrapper>
      <form className="content" onSubmit={onSubmit}>
        <label>
          Для восстановления введите почту на которую был создан аккаунт и мы
          вышлем вам ссылку по востановлению аккаунта
        </label>
        <div className="in">
          <InputRegister
            placeholder="Почта"
            type="email"
            name="email"
            value={values.email}
            onChange={changeHandler}
            autoComplete="off"
            isError={errorRemind}
          />
        </div>
        {errorRemind && <div className="error-message">{errorRemind}</div>}
        <div className="actions">
          <ButtonBlue text="Восстановить" width="420px" />
        </div>
      </form>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .content {
      margin-top: initial;
      .in {
        margin-top: 15px;
      }
    }
    .actions {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  label {
    padding-top: 15px;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default RemindPass;
