import React from "react";
import styled from "styled-components";

const ButtonGreen = ({ text, onClick }) => {
  return (
    <Wrapper>
      <button onClick={onClick}>{text}</button>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  button {
    height: 50px;
    padding: 13px 20px;
    background: var(--green-1);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Cera Pro Medium", sans-serif;
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default ButtonGreen;
