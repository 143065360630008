import React from "react";
import styled from "styled-components";

const ButtonBlue = ({ text, onClick, width }) => {
  return (
    <Wrapper width={width}>
      <button onClick={onClick}>{text}</button>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  button {
    height: 50px;
    padding: 13px 20px;
    background: var(--blue-1);
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-family: "Cera Pro Medium", sans-serif;
    width: ${(props) => (props.width ? props.width : "")};
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default ButtonBlue;
