import React from "react";
import styled from "styled-components";
import Offer from "./Offer";

const offers = [
  {
    id: 1,
    name: "Продажа аккаунта с 5000 подписками",
    type: "Тип номер 1",
    category: "Продажа",
    userName: "Микки Маус",
    userReviews: 5000,
    userStars: 5,
    userPrice: 234124,
    amount: 1,
  },
  {
    id: 2,
    name: "Продажа аккаунта с 4000 подписками",
    type: "Тип номер 1",
    category: "Продажа",
    userName: "Урфин Джюс",
    userReviews: 5000,
    userStars: 5,
    userPrice: 234124,
    amount: 1,
  },
  {
    id: 3,
    name: "Продажа аккаунта с 7000 подписками",
    type: "Тип номер 1",
    category: "Продажа",
    userName: "Соник Бум",
    userReviews: 5000,
    userStars: 5,
    userPrice: 234124,
    amount: 1,
  },
  {
    id: 4,
    name: "Продажа аккаунта с 7000 подписками",
    type: "Тип номер 1",
    category: "Продажа",
    userName: "Соник Бум",
    userReviews: 5000,
    userStars: 5,
    userPrice: 234124,
    amount: 1,
  },
];
const Offers = () => {
  return (
    <Wrapper>
      {offers.map((offer) => (
        <Offer key={offer.id} {...offer} />
      ))}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin-top: 20px;
  /* border-radius: 32px; */
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  border-radius: 30px;
  /* border: 1px solid gray; */
  border: 1px solid #f2f3f6;

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Offers;
