import React from "react";
import styled from "styled-components";

const HeroMenuItemSmall = ({ name, description, image }) => {
  return (
    <Wrapper>
      <div className="icon">
        <div className="icon-inner">{image}</div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  border-radius: 100%;
  width: 78px;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 1rem 0.3rem;
  :hover svg path {
    stroke: black;
  }
  &:hover {
    background: var(--green-1);
    color: black;
    .icon::before {
      border-top: 2px solid black;
      border-right: 2px solid black;
      border-bottom: 2px solid black;
      transform: rotate(45deg);
    }
  }
  .icon {
    width: 68px;
    height: 68px;
    flex-shrink: 0;
    background: rgba(236, 242, 255, 0.3);
    backdrop-filter: blur(39.1px);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    position: relative;
    &:hover {
      .icon-inner {
        background: var(--green-1);
        backdrop-filter: none;
      }
    }
    svg path {
      transition: 1s;
    }
  }
  .icon::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    width: 68px;
    height: 68px;
    border: 2px solid transparent;
    transition: 1s;
  }
  .icon-inner {
    width: 68px;
    height: 68px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: none;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default HeroMenuItemSmall;
