import React, { useState } from "react";
import styled from "styled-components";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

const InputRegisterPass = ({
  type,
  placeholder,
  value,
  name,
  onChange,
  autoComplete,
  isError,
}) => {
  const [showPass, setShowPass] = useState(false);

  const showPassHandler = () => {
    setShowPass(!showPass);
  };
  return (
    <Wrapper isError={isError}>
      <input
        type={showPass ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        autoComplete={autoComplete}
      />
      <div className="icon" onClick={showPassHandler}>
        {showPass ? <RiEyeCloseLine /> : <MdOutlineRemoveRedEye />}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  input {
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    padding: 13px 20px;
    /* background: var(--gray-2); */
    border: none;
    font-size: 100%;
    height: 50px;
    font-family: "Inter", sans-serif;
    border: 1px solid ${(props) => (props.isError ? "var(--red-1)" : "none")};
    background: ${(props) =>
      props.isError ? "var(--red-2)" : "var(--gray-2)"};
    :focus {
      /* border: 2px solid var(--blue-1); */
      border: 1px solid
        ${(props) => (props.isError ? "var(--red-1)" : "var(--blue-1)")};
      background: ${(props) => (props.isError ? "var(--red-2)" : "white")};
    }
    :focus-visible {
      outline: none;
    }
  }
  .icon {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    position: absolute;
    right: 5px;
    svg {
      font-size: 1.5rem;
      cursor: pointer;
      transition: var(--transition2);
      :hover {
        color: var(--black-2);
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default InputRegisterPass;
