import React, { useState, useRef } from "react";
import styled from "styled-components";
import { GrClose } from "react-icons/gr";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { registerBigHandler } from "../../features/user/userSlise";
import InputRegister from "./InputRegister";
import InputRegisterPass from "../../components-special/InputRegisterPass";
import { IoMdClose } from "react-icons/io";
import ButtonBlue from "../../components-special/ButtonBlue";
import {
  registerUser,
  loginUser,
  errorRemindHandler,
} from "../../features/user/userSlise";
import RemindPass from "./RemindPass";

const initialState = {
  email: "",
  password: "",
  isMember: true,
};

const RegisterModal = () => {
  const { isLoading, isErrorLogin } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const [values, setValues] = useState(initialState);
  const [isRemind, setIsRemind] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const toggleMemberHandler = () => {
    setValues({ ...values, isMember: !values.isMember });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { email, password, isMember } = values;
    if (!email || !password) {
      toast.error("Введите все значения");
      return;
    }

    if (isMember) {
      dispatch(loginUser({ email: email, password: password }));
      return;
    }
    dispatch(registerUser({ email, password }));
  };

  const closeHandler = () => {
    dispatch(registerBigHandler(false));
    dispatch(errorRemindHandler());
  };

  return (
    <Wrapper>
      <div className="modal">
        <div className="close" onClick={closeHandler}>
          <IoMdClose />
        </div>
        {isRemind ? (
          <div className="header">
            <p className="type-active">Восстановление пароля</p>
          </div>
        ) : (
          <div className="header">
            <p className="type-active" onClick={toggleMemberHandler}>
              {values.isMember ? "Вход" : "Регистрация"}
            </p>
            <p className="slash">/</p>
            <p className="type" onClick={toggleMemberHandler}>
              {values.isMember ? "Регистрация" : "Вход"}
            </p>
          </div>
        )}
        {isRemind ? (
          <RemindPass />
        ) : (
          <form className="content" onSubmit={onSubmit}>
            {values.isMember && (
              <label>Для входа введите вашу почту и пароль</label>
            )}
            {!values.isMember && (
              <label>
                Для регистрации введите вашу почту. после этого мы отправим вам
                письмо со ссылкой на личный кабинет
              </label>
            )}
            <div className="in in1">
              <InputRegister
                placeholder="Почта"
                type="email"
                name="email"
                value={values.email}
                onChange={changeHandler}
                autoComplete="off"
                isError={isErrorLogin}
              />
              {isErrorLogin && (
                <div className="error-message">
                  Не верно введена почта или пароль
                </div>
              )}
            </div>
            <div className="in">
              <InputRegisterPass
                placeholder="Пароль"
                type="password"
                name="password"
                value={values.password}
                onChange={changeHandler}
                autoComplete="off"
                isError={isErrorLogin}
              />
            </div>
            {isErrorLogin && (
              <div className="error-message">
                Не верно введена почта или пароль
              </div>
            )}
            <div className="actions">
              <ButtonBlue
                type="submit"
                text={values.isMember ? "Войти" : "Зарегистрироваться"}
                width={values.isMember ? "198px" : "420px"}
              />
              {values.isMember && (
                <p className="forgot" onClick={() => setIsRemind(true)}>
                  Забыли пароль?
                </p>
              )}
            </div>
          </form>
        )}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    transition: 1s;
    /* backdrop-filter: blur(10px); */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.6s;
    z-index: 999;
    opacity: 1;
    font-family: "Cera Pro Medium", sans-serif;

    .modal {
      width: 544px;
      min-height: 439px;
      background-color: white;
      padding: 60px;
      border-radius: 30px;
      border: 2px solid var(--gray-2);
      background: #fff;
      position: relative;
      .close {
        position: absolute;
        right: 29px;
        top: 29px;
        cursor: pointer;

        &:hover {
          svg {
            color: var(--blue-1);
          }
        }
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .slash {
      color: var(--green-1);
      width: 24px;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0 21px;
    }
    .type {
      font-family: "Cera Pro Medium", sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--black-1);
      opacity: 0.4;
      cursor: pointer;
    }
    .type-active {
      color: black;
      font-family: "Cera Pro Medium", sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;
    }
    .content {
      margin-top: 42px;
      label {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        opacity: 0.5;
      }
    }
    .in1 {
      margin-top: 15px;
    }
    .in {
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 40px;
      .forgot {
        margin-left: 50px;
        cursor: pointer;
      }
    }
    .error-message {
      color: var(--red-1);
      text-align: right;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default RegisterModal;
