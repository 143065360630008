import React from "react";
import styled from "styled-components";
import visa from "../../images/visa-logo.png";

const VisaOne = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="line">
          <img src={visa} alt="logo" />
          <img src={visa} alt="logo" />
        </div>
        <div className="line">
          <img src={visa} alt="logo" />
          <img src={visa} alt="logo" />
        </div>
        <div className="line2">
          <img src={visa} alt="logo" />
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  /* border: 1px solid gray; */
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #eef0f3;
  width: 100%;
  background: var(--blue-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .content {
    padding: 35px 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  .line2 {
    width: 100%;
    display: flex;
    margin: 20px 0;
  }
  img {
    width: 90px;
    margin: 10px;
  }

  @media (min-width: 576px) {
    img {
      width: 130px;
      margin: 20px 30px;
    }
  }
  @media (min-width: 768px) {
    img {
      width: 150px;
      margin: 30px 50px;
    }
  }
  @media (min-width: 992px) {
    width: 32%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 443px;
    padding: 25px 25px;
    .content {
      padding: 0;
    }
    img {
      width: 100px;
      margin: 15px 15px;
    }
  }
  @media (min-width: 1200px) {
    img {
      width: 110px;
    }
  }
  @media (min-width: 1400px) {
  }
`;
export default VisaOne;
