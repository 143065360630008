import React from "react";
import styled from "styled-components";
import logo from "../../images/Logo.png";
import { useNavigate } from "react-router-dom";

const SmallSearch = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <img src={logo} alt="" />
      <button
        onClick={() => {
          navigate("/search");
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M19.7408 17.2878C20.0926 17.678 20.0926 18.2634 19.7017 18.6146L18.6072 19.7073C18.2554 20.0976 17.669 20.0976 17.2781 19.7073L13.4081 15.8439C13.2127 15.6488 13.1345 15.4146 13.1345 15.1805V14.5171C11.7272 15.6098 10.0072 16.2341 8.13088 16.2341C3.63544 16.2341 0 12.6049 0 8.11707C0 3.66829 3.63544 0 8.13088 0C12.5872 0 16.2618 3.66829 16.2618 8.11707C16.2618 10.0293 15.5972 11.7463 14.5418 13.1122H15.1672C15.4018 13.1122 15.6363 13.2293 15.8318 13.3854L19.7408 17.2878ZM8.13088 13.1122C10.8672 13.1122 13.1345 10.8878 13.1345 8.11707C13.1345 5.38537 10.8672 3.12195 8.13088 3.12195C5.35543 3.12195 3.12726 5.38537 3.12726 8.11707C3.12726 10.8878 5.35543 13.1122 8.13088 13.1122Z"
            fill="black"
          />
        </svg>
      </button>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 1rem; */
  padding: 0.5rem 1rem;

  button {
    display: flex;
    height: 40px;
    padding: 11px 12px 13px 12px;
    flex-direction: column;
    align-items: center;
    border-radius: 50%;
    background: var(--green-1);
    border: none;
    cursor: pointer;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: none;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default SmallSearch;
