import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import CollectionSmallMenu from "../user-components/catalog/CollectionSmallMenu";
import { useNavigate } from "react-router-dom";
import SmallSearch from "../user-components/navigation/SmallSearch";
import SmallMenu from "../user-components/navigation/SmallMenu";
import { smallMenuHandler } from "../features/user/userSlise";

const HIDE_SCROLL_THRESHOLD = 60;

const SmallCatalogPage = () => {
  const { categories } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 992) {
      mainHandler();
    }
  }, [windowWidth]);

  const rootCategories = _.filter(categories, (c) => c.parentId === null);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      let currentScroll = window.scrollY;

      if (
        currentScroll > lastScrollTop &&
        currentScroll > HIDE_SCROLL_THRESHOLD
      ) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  const mainHandler = () => {
    dispatch(smallMenuHandler("Главная"));
    navigate("/");
  };

  return (
    <Wrapper>
      <SmallSearch />

      <div className="header" onClick={mainHandler}>
        <MdOutlineKeyboardArrowLeft />
        <p>Каталог предложений</p>
      </div>
      <div className="smallMenu">
        {rootCategories.map((category) => (
          <CollectionSmallMenu
            key={category._id}
            category={category}
            categories={categories}
            level={0}
          />
        ))}
      </div>
      <div className="footer">
        <p>Вопрос и ответ</p>
        <p>Поддержака</p>
        <p>Оплата</p>
        <p>О нас</p>
        <p>Блог и статьи</p>
      </div>
      <SmallMenu isVisible={isVisible} />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin-top: 15px;
  .header {
    margin: 20px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      font-size: 18px;
      padding-left: 10px;
    }
    svg {
      font-size: 34px;
      cursor: pointer;
    }
  }
  .footer {
    width: 100vw;
    display: flex;
    padding: 20px 12px;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    background: var(--blue-1);
    margin-top: 40px;
    p {
      color: white;
      font-size: 16px;
      font-weight: 500;
      margin: 10px;
      cursor: pointer;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: none;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default SmallCatalogPage;
