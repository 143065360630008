import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../images/Logo.png";
import { GrClose } from "react-icons/gr";
import SmallMenu from "../user-components/navigation/SmallMenu";
import { useNavigate } from "react-router-dom";
import SearchSmallInput from "../user-components/search/SearchSmallInput";

const HIDE_SCROLL_THRESHOLD = 60;

const SearchSmallPage = () => {
  const navigate = useNavigate();

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      let currentScroll = window.scrollY;

      if (
        currentScroll > lastScrollTop &&
        currentScroll > HIDE_SCROLL_THRESHOLD
      ) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 992) {
      navigate("/");
    }
  }, [windowWidth]);

  return (
    <>
      <Wrapper>
        <div className="header">
          <img src={logo} alt="" />
          <div className="close"></div>
          <GrClose
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className="search">
          <SearchSmallInput />
        </div>
        <div className="content">
          <p>Введите необходимое</p>
          <p>в поисковой строке</p>
        </div>
      </Wrapper>
      <SmallMenu isVisible={isVisible} />
    </>
  );
};
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 0.5rem 1rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 13px;
    svg {
      font-size: 24px;
      cursor: pointer;
    }
  }
  .search {
    margin: 20px 0;
  }
  .content {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Cera Pro Medium", sans-serif;
  }
  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    opacity: 0.2;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: none;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default SearchSmallPage;
