import React from "react";
import styled from "styled-components";
import logo from "../../images/Logo white.png";

const FooterLine2 = () => {
  return (
    <Wrapper>
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="links">
        <p>Вопрос и ответ</p>
        <p>Поддержка</p>
        <p>Оплата</p>
        <p>О нас</p>
        <p>Блог</p>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;
  .links {
    display: flex;
    flex-wrap: wrap;
    p {
      margin: 10px;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 980px;
    flex-direction: row;
    .links {
      p {
        margin: 10px;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
  @media (min-width: 1200px) {
    width: 1180px;
  }
  @media (min-width: 1400px) {
  }
`;
export default FooterLine2;
