import React from "react";
import styled from "styled-components";
import { remind_okHandler } from "../../features/user/userSlise";
import { useSelector, useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import success from "../../images/Success2.png";

const SuccessModal = () => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <div className="modal">
        <div className="close" onClick={() => dispatch(remind_okHandler())}>
          <IoMdClose />
        </div>
        <div className="content">
          <p className="type-active">Отлично!</p>
          <label>
            Мы уже отправили На вашу почту письмо с активацией вашего личного
            кабинета!
          </label>
          <label>Скоро увидемся!</label>
        </div>
        <img className="success" src={success} alt="success" />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  transition: 1s;
  /* backdrop-filter: blur(10px); */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.6s;
  z-index: 999;
  opacity: 1;
  font-family: "Cera Pro Medium", sans-serif;
  .modal {
    width: 544px;
    background-color: white;
    padding: 60px;
    border-radius: 30px;
    border: 2px solid var(--gray-2);
    background: #fff;
    position: relative;
    .close {
      position: absolute;
      right: 29px;
      top: 29px;
      cursor: pointer;

      &:hover {
        svg {
          color: var(--blue-1);
        }
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .type-active {
    color: black;
    font-family: "Cera Pro Medium", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
  label {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.5;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .success {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default SuccessModal;
