import React from "react";
import styled from "styled-components";

const ButtonSell = ({ text }) => {
  return (
    <Wrapper>
      <p>{text}</p>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  height: 50px;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: 10px;
  border-radius: 10px;
  border: 2px solid var(--blue-1);
  color: var(--blue-1);
  cursor: pointer;

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default ButtonSell;
