import React from "react";
import styled from "styled-components";

const AboutLink = () => {
  return (
    <Wrapper>
      <div className="line">
        <div className="number-one">
          <p>01</p>
        </div>
        <div className="info">
          <p className="info-one">Свяжитесь с продавцом</p>
          <p className="info-two">Убедитесь, что он на связи и лот актуален</p>
        </div>
      </div>
      <div className="line">
        <div className="number">02</div>
        <div className="info">
          <p className="info-one">Оплатите заказ</p>
          <p className="info-two-gray">
            Средства будут зарезервированы в системе и не поступят продавцу без
            вашего согласия.
          </p>
        </div>
      </div>
      <div className="line">
        <div className="number">03</div>
        <div className="info">
          <p className="info-one">Получите оплаченый товар</p>
          <p className="info-two-gray">Проверьте, всё ли в порядке с лотом.</p>
        </div>
      </div>
      <div className="line">
        <div className="number">04</div>
        <div className="info">
          <p className="info-one">Подтвердите сделку</p>
          <p className="info-two-gray">
            Средства поступят продавцу. Не забудьте оставить отзыв, ему будет
            приятно!
          </p>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 15px 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #eef0f3;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  .line {
    display: flex;
    align-items: center;
    margin: 20px 0;
    .number {
      margin: 15px;
      margin-right: 35px;
      margin-left: 25px;
    }
    .info-one {
      font-size: 16px;
    }
    .info-two {
      font-size: 12px;
      color: var(--gray-1);
    }
    .info-two-gray {
      font-size: 12px;
      color: var(--gray-3);
    }
  }
  .number-one {
    width: 68px;
    height: 68px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--blue-2);
    border: 2px solid var(--blue-1);
    border-left-color: transparent;
    transform: rotate(45deg);
    p {
      transform: rotate(-45deg);
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 45%;
    padding: 30px 40px;
    border-radius: 0;
    .info-one {
      font-size: 20px;
    }
    .info-two {
      font-size: 14px;
    }
    .info-two-gray {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AboutLink;
