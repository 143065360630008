import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AboutSave from "./AboutSave";
import AboutLink from "./AboutLink";
import AboutMessage from "./AboutMessage";

const SvgAbout = () => {
  return (
    <SvgWrapper>
      <svg width="303" height="42" viewBox="0 0 303 42" fill="none">
        <path
          d="M0.999023 30C0.999023 13.9837 13.9828 1 29.999 1H256.141C265.191 1 273.723 5.22554 279.208 12.4247L300.98 41H151.499H0.999023V30Z"
          fill="white"
          stroke="#F2F3F6"
        />
        <text
          x="30"
          y="25"
          fontFamily="Verdana"
          fontSize="16"
          fill="var(--blue-1)"
        >
          Как это работает
        </text>
      </svg>
    </SvgWrapper>
  );
};

const AmoutMain = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Wrapper>
      <div className="about-header">
        <p>О проекте</p>
      </div>
      {isMobile ? (
        <div className="content">
          <SvgAbout />
          <AboutSave />
          <AboutMessage />
          <AboutLink />
        </div>
      ) : (
        <div className="content">
          <SvgAbout />
          <AboutSave />
          <AboutLink />
          <AboutMessage />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 20px;
  width: 95%;
  .about-header {
    width: 100%;
    display: flex;
    font-size: 24px;
    justify-content: flex-start;
  }
  .content {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 980px;
    .about-header {
      font-size: 40px;
      justify-content: center;
      margin-bottom: 80px;
    }
    .content {
      flex-direction: row;
    }
  }
  @media (min-width: 1200px) {
    width: 1180px;
  }
  @media (min-width: 1400px) {
  }
`;

const SvgWrapper = styled.div`
  display: none;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: block;
    position: absolute;
    top: -42px;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AmoutMain;
