import React from "react";
import styled from "styled-components";
import news1 from "../../images/news1.png";
import news2 from "../../images/news2.png";
import news3 from "../../images/news3.png";
import news4 from "../../images/news4.png";
import ButtonSell from "../navigation/ButtonSell";

import NewsLine1Big from "./NewsLine1Big";
import NewsLine2Big from "./NewsLine2Big";

const news = [
  {
    id: "1",
    name: "Digital биржа с низкой комиссией и лояльной поддержкой",
    date: "22.11.2023",
    timeToRead: "4 минуты",
    image: news1,
  },
  {
    id: "2",
    name: "Digital биржа с низкой комиссией и лояльной поддержкой",
    date: "23.11.2023",
    timeToRead: "7 минут",
    image: news2,
  },
  {
    id: "3",
    name: "Digital биржа с низкой комиссией и лояльной поддержкой",
    date: "23.11.2023",
    timeToRead: "7 минут",
    image: news3,
  },
  {
    id: "4",
    name: "Digital биржа с низкой комиссией и лояльной поддержкой",
    date: "23.11.2023",
    timeToRead: "7 минут",
    image: news4,
  },
];

const NewsBigMain = () => {
  return (
    <Wrapper news={news}>
      <div className="about-header">
        <p>Новости и статьи</p>
        <ButtonSell text="Показать все" />
      </div>
      <div className="news">
        <div className="line1">
          {news.slice(0, 2).map((n) => (
            <NewsLine1Big
              key={n.id}
              background={n.image}
              name={n.name}
              timeToRead={n.timeToRead}
              date={n.date}
            />
          ))}
        </div>
        <div className="line2">
          {news.map((n) => (
            <NewsLine2Big
              key={n.id}
              background={n.image}
              name={n.name}
              timeToRead={n.timeToRead}
              date={n.date}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: none;
  font-family: "Cera Pro Medium", sans-serif;

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: block;
    width: 980px;
    margin-top: 80px;

    .about-header {
      display: flex;
      font-size: 40px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .news {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .line1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 300px;
    }
    .line2 {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 300px;
      margin-top: 20px;
    }
  }
  @media (min-width: 1200px) {
    width: 1180px;
    margin-top: 20px;
    .line1 {
      height: 350px;
    }
  }
  @media (min-width: 1400px) {
    .line1 {
      height: 370px;
    }
  }
`;
export default NewsBigMain;
