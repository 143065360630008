import React from "react";
import styled from "styled-components";
import login_img from "../../images/login.png";
import Language from "./Language";
import { useSelector, useDispatch } from "react-redux";
import IsUserPanelBig from "./IsUserPanelBig";
import { registerBigHandler } from "../../features/user/userSlise";

const UserPanel = () => {
  const { isLoading, user } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Language />
      {!user && (
        <div
          className="register"
          onClick={() => dispatch(registerBigHandler(true))}
        >
          <img src={login_img} alt="login" />
          <p>Вход / Регистрация</p>
        </div>
      )}
      {user && <IsUserPanelBig />}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .register {
    width: 220px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    /* border: 1px solid gray; */
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default UserPanel;
