import React from "react";
import styled from "styled-components";

const AboutMessage = () => {
  return (
    <Wrapper>
      <div className="rect1">
        <div className="rect2">
          <p>0.01%</p>
        </div>
      </div>
      <div className="rate">
        <p>Процентная ставка</p>
      </div>
      <div className="circles">
        <div className="circle-big">
          <div className="circle-small2"></div>
        </div>
        <div className="circle-small"></div>
        <div className="circle-small"></div>
        <div className="circle-small"></div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #eef0f3;
  padding: 20px;
  background: var(--blue-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .rect1 {
    background: white;
    height: 180px;
    width: 115px;
    transform: rotate(68deg);
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    .rect2 {
      background: var(--green-1);
      height: 168px;
      width: 103px;
      border-radius: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 55px;
        transform: rotate(-68deg);
        font-weight: 900;
      }
    }
  }
  .rate {
    margin-top: 10px;
    p {
      color: white;
      font-size: 16px;
    }
  }
  .circles {
    display: flex;
    margin: 10px;
    align-items: center;
    .circle-big {
      width: 18px;
      height: 18px;
      border: 1px solid white;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left-color: transparent;
      transform: rotate(45deg);
      margin: 0 10px;
    }
    .circle-small {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: white;
      opacity: 0.5;
      margin: 0 10px;
    }
    .circle-small2 {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: white;
      opacity: 0.5;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 32%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 30px 40px;
    .rate {
      margin-top: 20px;
      p {
        font-size: 20px;
      }
    }
    .circles {
      margin: 20px;

      .circle-big {
        width: 32px;
        height: 32px;
        border: 2px solid white;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left-color: transparent;
        transform: rotate(45deg);
        margin: 0 10px;
      }
      .circle-small {
        width: 20px;
        height: 20px;
        margin: 0 15px;
      }
      .circle-small2 {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media (min-width: 1200px) {
    .rect1 {
      height: 260px;
      width: 167px;
      border-radius: 37px;

      .rect2 {
        height: 248px;
        width: 155px;
        border-radius: 41px;
        p {
          font-size: 80px;
        }
      }
    }
  }
  @media (min-width: 1400px) {
  }
`;
export default AboutMessage;
