import React from "react";
import styled from "styled-components";
import ButtonBlue from "../../components-special/ButtonBlue";
import ButtonGreen from "../../components-special/ButtonGreen";

const HeroActions = () => {
  return (
    <Wrapper>
      <ButtonBlue text="Подробнее" />
      <div className="action2">
        <ButtonGreen text="Войти" />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: white;
  display: flex;
  align-items: end;
  margin-left: 1rem;
  /* border: 1px solid white; */

  div:first-child {
    margin: 0.5rem;
  }
  .action2 {
    display: none;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    margin-bottom: 2rem;
    margin-left: 0;

    .action2 {
      display: block;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default HeroActions;
