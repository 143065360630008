import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SmallMenu from "../user-components/navigation/SmallMenu";
import { useDispatch, useSelector } from "react-redux";
import SmallSearch from "../user-components/navigation/SmallSearch";
import toast from "react-hot-toast";
import { registerUser, loginUser } from "../features/user/userSlise";
import InputRegister from "../user-components/registerBig/InputRegister";
import InputRegisterPass from "../components-special/InputRegisterPass";
import ButtonBlue from "../components-special/ButtonBlue";
import { useNavigate } from "react-router-dom";
import frame from "../images/Frame 104.png";
import { IoMdClose } from "react-icons/io";

const HIDE_SCROLL_THRESHOLD = 60;

const initialState = {
  email: "",
  password: "",
  isMember: true,
  remind: "",
};

const SmallRegistrationPage = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { isLoading, isErrorLogin, user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 992) {
      navigate("/");
    }
  }, [windowWidth]);

  const [values, setValues] = useState(initialState);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const toggleMemberHandler = () => {
    setValues({ ...values, isMember: !values.isMember });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { email, password, isMember } = values;
    if (!email || !password) {
      toast.error("Введите все значения");
      return;
    }
    if (isMember) {
      dispatch(loginUser({ email: email, password: password }));
      return;
    }
    dispatch(registerUser({ email, password }));
  };

  useEffect(() => {
    const handleScroll = () => {
      let currentScroll = window.scrollY;
      if (
        currentScroll > lastScrollTop &&
        currentScroll > HIDE_SCROLL_THRESHOLD
      ) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  const forgotHandler = () => {
    navigate("/remind");
  };

  return (
    <Wrapper>
      <div className="search">
        <SmallSearch />
      </div>
      <img className="frame" src={frame} alt="frame" />
      <div className="close" onClick={() => navigate("/")}>
        <IoMdClose />
      </div>
      <div className="main">
        <div className="header">
          <p className="type-active" onClick={toggleMemberHandler}>
            {values.isMember ? "Вход" : "Регистрация"}
          </p>
          <p className="slash">/</p>
          <p className="type" onClick={toggleMemberHandler}>
            {values.isMember ? "Регистрация" : "Вход"}
          </p>
        </div>
        <form className="content" onSubmit={onSubmit}>
          {values.isMember && (
            <label>Для входа введите вашу почту и пароль</label>
          )}
          {!values.isMember && (
            <label>
              Для регистрации введите вашу почту. после этого мы отправим вам
              письмо со ссылкой на личный кабинет
            </label>
          )}
          <div className="in">
            <InputRegister
              placeholder="Почта"
              type="email"
              name="email"
              value={values.email}
              onChange={changeHandler}
              autoComplete="off"
              isError={isErrorLogin}
            />
            {isErrorLogin && (
              <div className="error-message">
                Не верно введена почта или пароль
              </div>
            )}
          </div>
          <div className="in">
            <InputRegisterPass
              placeholder="Пароль"
              type="password"
              name="password"
              value={values.password}
              onChange={changeHandler}
              autoComplete="off"
              isError={isErrorLogin}
            />
          </div>
          {isErrorLogin && (
            <div className="error-message">
              Не верно введена почта или пароль
            </div>
          )}
          <div className="actions">
            <ButtonBlue
              type="submit"
              text={values.isMember ? "Войти" : "Зарегистрироваться"}
              width="316px"
            />
            {values.isMember && (
              <p className="forgot" onClick={forgotHandler}>
                Забыли пароль?
              </p>
            )}
          </div>
        </form>
      </div>
      <div className="search">
        <SmallMenu isVisible={isVisible} />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  .search {
    display: none;
  }
  .close {
    background: var(--blue-1);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    svg {
      width: 35px;
      height: 35px;
      color: white;
    }
  }
  .frame {
    width: 100%;
  }
  .main {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Cera Pro Medium", sans-serif;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
    width: 316px;
  }
  .slash {
    color: var(--green-1);
    width: 24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 10px;
  }
  .type {
    font-family: "Cera Pro Medium", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--black-1);
    opacity: 0.4;
    cursor: pointer;
  }
  .type-active {
    color: black;
    font-family: "Cera Pro Medium", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
  .content {
    /* margin-top: 42px; */
    display: flex;
    flex-direction: column;
    label {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      opacity: 0.5;
      max-width: 316px;
      flex-wrap: wrap;
    }
  }

  .in {
    margin-top: 15px;
    /* margin-bottom: 15px; */
  }
  .actions {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
    margin-top: 14px;
    .forgot {
      /* margin-left: 50px; */
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .error-message {
    color: var(--Red, #eb5757);
    text-align: right;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  @media (min-width: 576px) {
    .frame {
      display: none;
    }
    .main {
      height: 80vh;
    }
    .close {
      display: none;
    }
    .search {
      display: block;
    }
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default SmallRegistrationPage;
