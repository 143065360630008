import React, { useState } from "react";

import styled from "styled-components";

const AboutSave = () => {
  return (
    <Wrapper>
      <p className="save-order">
        Digital биржа с низкой комиссией и лояльной поддержкой
      </p>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #eef0f3;
  background: #fff;
  padding: 20px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  /* border: 1px solid gray; */

  .save-order {
    font-size: 20px;
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 23%;
    padding: 30px 40px;
    border-radius: 0;
    border-bottom-left-radius: 30px;
    .save-order {
      font-size: 25px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AboutSave;
