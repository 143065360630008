import React from "react";
import styled from "styled-components";
import main_image from "../../images/main-image.avif";
import HeroInfo from "./Hero-info";
import HeroActions from "./HeroActions";
import HeroMenu from "./HeroMenu";
import HeroMenuSmall from "./HeroMenuSmall";

const HeroMain = () => {
  return (
    <Wrapper background={`${main_image}`}>
      <HeroInfo />
      <HeroActions />
      <HeroMenu />
      <HeroMenuSmall />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  border-radius: 32px;
  width: 95%;
  /* height: 75vh; */
  height: 452px;
  display: flex;
  flex-direction: column;
  font-family: "Cera Pro Medium";

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    max-width: 980px;
    height: 450px;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 1200px) {
    max-width: 1180px;
    height: 540px;
  }
  @media (min-width: 1400px) {
  }
`;
export default HeroMain;
