import React from "react";
import styled from "styled-components";
import avatar from "../../images/Avatar2.png";
import avatar4 from "../../images/Ellipse 4.png";
import star from "../../images/Star 1.png";

const StarSVG = () => (
  <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
    <path
      d="M4.84216 0.440796L5.8121 3.42597L8.9509 3.42597L6.41156 5.27091L7.3815 8.25609L4.84216 6.41115L2.30282 8.25609L3.27276 5.27091L0.733418 3.42597L3.87222 3.42597L4.84216 0.440796Z"
      fill="#1766FF"
    />
  </svg>
);
const StarSVG3 = () => (
  <svg width="14" height="14" viewBox="0 0 9 9" fill="none">
    <path
      d="M4.84216 0.440796L5.8121 3.42597L8.9509 3.42597L6.41156 5.27091L7.3815 8.25609L4.84216 6.41115L2.30282 8.25609L3.27276 5.27091L0.733418 3.42597L3.87222 3.42597L4.84216 0.440796Z"
      fill="#1766FF"
    />
  </svg>
);

const Review = () => {
  return (
    <Wrapper>
      <div className="window">
        <div className="window-panel">
          <div className="avatar">
            <img src={avatar} alt="avatar" />
          </div>
          <div className="avatar-info">
            <div className="stars">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="line1">Микки Маус</div>
            <div className="line2">Услуги по продаже аккаунтов</div>
          </div>
        </div>
        <div className="cloud">Отличный продавец!</div>
        <div className="window-header">
          <div className="header-user">
            <img src={avatar4} alt="avatar" />
            <p className="avatar-name">Микки Маус</p>
          </div>
          <p className="date">22.11.2023</p>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 16px 35px 15px 35px; */
  margin: 10px;
  .window {
    width: 300px;
    height: 266px;
    border-radius: 18px;
    border-radius: 18px;
    background: var(--blue-2);
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .window-panel {
    width: 300px;
    height: 76px;
    display: flex;
    align-items: center;
    background: var(--blue-1);
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 12px 20px;
    color: white;
  }
  .cloud {
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 10px 20px;
  }

  .avatar {
    img {
      border-radius: 50%;
      width: 49px;
    }
  }
  .avatar-info {
    margin-left: 10px;
  }

  .line1 {
  }
  .line2 {
    font-size: 14px;
    word-break: keep-all;
    color: var(--gray-5);
    width: 100%;
  }

  .input {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  .window-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    .header-user {
      width: 100%;
      display: flex;
      align-items: center;
      p {
        margin-left: 10px;
      }
    }
  }
  .cloud {
    padding: 30px;
    padding-left: 40px;
  }
  .avatar-name {
    font-size: 16px;
  }
  .date {
    font-size: 14px;
    opacity: 0.4;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .window {
      width: 459px;
    }
    .window-panel {
      padding: 30px;
      width: 459px;
      position: relative;
    }
    .line1 {
      font-size: 20px;
    }
    .line2 {
      font-size: 14px;
    }
    .avatar-info {
      .stars {
        position: absolute;
        right: 30px;
        top: 10px;
      }
    }
    .header-user {
      padding: 10px 30px;
    }
    .date {
      margin-right: 30px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Review;
