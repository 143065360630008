import React from "react";
import styled from "styled-components";
import UserNavLink from "./UserNavLink";
import UserPanel from "./UserPanel";

const UserNavbar = () => {
  return (
    <NavContainer>
      <UserNavLink />
      <div className="user-panel">
        <UserPanel />
      </div>
    </NavContainer>
  );
};
const NavContainer = styled.div`
  /* max-width: 1180px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid gray; */
  font-family: "Cera Pro Medium", sans-serif;

  .user-panel {
    display: flex;
    .register {
      width: 220px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      /* border: 1px solid gray; */
    }
  }
  display: none;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: flex;
    width: 980px;
  }

  @media (min-width: 1200px) {
    width: 1180px;
  }
  @media (min-width: 1400px) {
  }
`;
export default UserNavbar;
