import React from "react";
import styled from "styled-components";
import FooterLine1 from "./FooterLine1";
import FooterLine2 from "./FooterLine2";
import FooterLine3 from "./FooterLine3";

const FooterMain = () => {
  return (
    <Wrapper>
      <FooterLine1 />
      <FooterLine2 />
      <FooterLine3 />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  background: black;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  padding: 0px 12px;
  padding-top: 80px;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    padding: 50px 0;
    align-items: center;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default FooterMain;
