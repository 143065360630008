import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import icon_ru from "../../images/ru.png";
import icon_us from "../../images/US.png";
import { IoIosArrowDown } from "react-icons/io";

const Language = () => {
  const [expand, setExpand] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setExpand(false);
    }
  };

  useEffect(() => {
    if (expand) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [expand]);

  return (
    <Wrapper ref={wrapperRef} expand={expand}>
      <div className="current-lang">
        <img src={icon_ru} alt="lang" />
        <p>Русский</p>
        <IoIosArrowDown onClick={() => setExpand(!expand)} />
      </div>
      {expand && (
        <div className="langs-list">
          <div className="lang-item">
            <img src={icon_ru} alt="lang" />
            <p>Русский</p>
          </div>
          <div className="lang-item">
            <img src={icon_us} alt="lang" />
            <p>English</p>
          </div>
        </div>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: relative;
  .current-lang {
    width: 150px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: ${(props) => (props.expand ? "var(--gray-2)" : "transparent")};
    border-radius: 10px;
  }
  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transform: ${(props) => (props.expand ? "rotate(180deg)" : "")};
  }
  .langs-list {
    position: absolute;
    top: 50px;
    left: -20px;
    background: white;
    width: 190px;
    /* padding: 20px; */
    border-radius: 10px;
    border: 1px solid var(--backgroun-1);
    background: #fff;
    z-index: 2;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
    .lang-item {
      display: flex;
      justify-content: center;
      margin: 15px;
      cursor: pointer;
      transition: 0.7s;
      :hover {
        background-color: var(--gray-2);
      }
      p {
        margin-left: 10px;
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Language;
