import React from "react";
import styled from "styled-components";
import _ from "lodash";

const CollectionFooter = ({ category, categories, level }) => {
  const children = _.filter(categories, (c) => c.parentId === category._id);
  const length = children.length;

  return (
    <Wrapper level={level} length={length}>
      <div className="line">
        {/* <p>{level}</p> */}
        <p
          className={
            level === 0
              ? "categoryName"
              : length > 0
              ? "categoryName2"
              : "categoryNameLast"
          }
        >
          {category?.name}
        </p>
      </div>
      <div className="children">
        {children.map((child) => (
          <CollectionFooter
            key={child._id}
            category={child}
            categories={categories}
            level={level + 1}
          />
        ))}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .line {
    display: flex;
    justify-content: start;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: ${(props) => (props.level === 0 ? "270px" : "220px")};
    display: flex;
    flex-direction: column;
    .line {
      justify-content: flex-start;
      margin-top: ${(props) => (props.level === 0 ? "20px" : "0px")};
    }
  }
  .categoryName {
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .categoryName2 {
    opacity: 0.5;
    cursor: pointer;
  }
  .categoryNameLast {
    cursor: pointer;
  }

  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default CollectionFooter;
