import React from "react";
import styled from "styled-components";
import ButtonSell from "../navigation/ButtonSell";
import NewsCardSmall from "./NewsCardSmall";
import news1 from "../../images/news1.png";
import news2 from "../../images/news2.png";
import news3 from "../../images/news3.png";
import news4 from "../../images/news4.png";
const news = [
  {
    id: "1",
    name: "Digital биржа с низкой комиссией и лояльной поддержкой",
    date: "22.11.2023",
    timeToRead: "4 минуты",
    image: news1,
  },
  {
    id: "2",
    name: "Digital биржа с низкой комиссией и лояльной поддержкой",
    date: "23.11.2023",
    timeToRead: "7 минут",
    image: news2,
  },
  {
    id: "3",
    name: "Digital биржа с низкой комиссией и лояльной поддержкой",
    date: "23.11.2023",
    timeToRead: "7 минут",
    image: news3,
  },
  {
    id: "4",
    name: "Digital биржа с низкой комиссией и лояльной поддержкой",
    date: "23.11.2023",
    timeToRead: "7 минут",
    image: news4,
  },
];
const NewsSmallMain = ({ name, timeToRead, date, background }) => {
  return (
    <Wrapper>
      <p className="head-text">Новости и статьи</p>
      <div className="cards">
        {news.map((n) => (
          <NewsCardSmall
            key={n.id}
            background={n.image}
            name={n.name}
            timeToRead={n.timeToRead}
            date={n.date}
          />
        ))}
      </div>
      <ButtonSell text="Показать все" />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: 20px;
  width: 95%;
  display: flex;
  flex-direction: column;
  .head-text {
    font-size: 24px;
    color: black;
  }
  .cards {
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none; // Скрывает скроллбар для Webkit-браузеров (например, Chrome и Safari)
    }
    -ms-overflow-style: none; // Скрывает скроллбар в IE и Edge
    scrollbar-width: none; // Скрывает скроллбар в Firefox
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: none;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default NewsSmallMain;
