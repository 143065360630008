import React from "react";
import styled from "styled-components";
import OfferHeader from "./OfferHeader";
import Offers from "./Offers";
import ButtonBlue from "../../components-special/ButtonBlue";

const OffersMain = () => {
  return (
    <Wrapper>
      <OfferHeader />
      <Offers />
      <div className="actions">
        <button>Показать больше</button>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: 20px;
  width: 95%;

  .actions {
    display: flex;
    width: 100%;
    justify-content: center;
    button {
      height: 50px;
      width: 100%;
      padding: 13px 20px;
      background: var(--blue-1);
      border: none;
      border-radius: 10px;
      color: white;
      font-family: "Cera Pro Medium", sans-serif;
      cursor: pointer;
    }
  }

  @media (min-width: 576px) {
    .actions {
      button {
        width: max-content;
      }
    }
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 980px;
  }
  @media (min-width: 1200px) {
    width: 1180px;
  }
  @media (min-width: 1400px) {
  }
`;
export default OffersMain;
