import React from "react";
import styled from "styled-components";
import { IoIosArrowForward } from "react-icons/io";

const NewsLine2Big = ({ name, timeToRead, date, background }) => {
  return (
    <Wrapper background={background}>
      <div className="card">
        <div className="picture">
          <div className="tag">Tag</div>
        </div>
        <div className="info">
          <div className="info-line1">
            <p>{timeToRead}</p>
            <p>{date}</p>
          </div>
          <div className="info-line2">{name}</div>
          <div className="info-line3">
            <p>Подробнее</p>
            <IoIosArrowForward />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 25%;
    :nth-child(1),
    :nth-child(2),
    :nth-child(3) {
      margin-right: 20px;
    }
    .picture {
      background-image: url(${(props) => props.background});
      background-size: cover;
      background-position: center;
      border-radius: 20px;
      height: 170px;
      padding: 20px;
      .tag {
        padding: 6px 10px;
        width: min-content;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.2);
        color: white;
        font-size: 14px;
      }
    }
    .info {
      margin: 10px 0;
      .info-line1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: var(--gray-1);
        padding-right: 5px;
      }
      .info-line2 {
        margin-top: 10px;
        font-size: 16px;
      }
      .info-line3 {
        margin-top: 20px;
        display: flex;
        align-items: center;

        cursor: pointer;
        svg {
          margin-left: 10px;
          color: var(--blue-1);
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .picture {
      height: 190px;
    }
  }
  @media (min-width: 1400px) {
    .picture {
      height: 200px;
    }
  }
`;
export default NewsLine2Big;
