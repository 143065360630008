import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SmallSearch from "../user-components/navigation/SmallSearch";
import { useDispatch, useSelector } from "react-redux";
import SmallMenu from "../user-components/navigation/SmallMenu";
import ButtonBlue from "../components-special/ButtonBlue";
import { useNavigate } from "react-router-dom";
import InputRegister from "../user-components/registerBig/InputRegister";
import {
  remindPass,
  remind_okHandler,
  errorRemindHandler,
} from "../features/user/userSlise";
import success from "../images/Success.png";
import frame from "../images/Frame 104.png";
import { IoMdClose } from "react-icons/io";
import logo from "../images/Logo (1).png";
import group103 from "../images/Group 103 (1).png";

const HIDE_SCROLL_THRESHOLD = 60;

const SmallRemindPage = () => {
  const navigate = useNavigate();

  const { isLoading, remind_ok, errorRemind } = useSelector(
    (store) => store.user
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(errorRemindHandler());

    dispatch(remind_okHandler());
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let currentScroll = window.scrollY;

      if (
        currentScroll > lastScrollTop &&
        currentScroll > HIDE_SCROLL_THRESHOLD
      ) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 992) {
      navigate("/");
    }
  }, [windowWidth]);

  const [values, setValues] = useState("");

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { email } = values;
    if (!email) {
      //   toast.error("Введите все значения");

      return;
    }
    dispatch(remindPass({ remind_email: email }));
  };

  const closeHandler = () => {
    dispatch(remind_okHandler());
    navigate("/");
  };

  return (
    <Wrapper>
      <div className="search">
        <SmallSearch />
      </div>
      {!remind_ok && <img className="frame" src={frame} alt="frame" />}
      {!remind_ok && (
        <div className="close" onClick={closeHandler}>
          <IoMdClose />
        </div>
      )}
      {remind_ok && (
        <div className="close2" onClick={closeHandler}>
          <IoMdClose />
        </div>
      )}
      {remind_ok && (
        <div className="logo-container">
          <img className="image1" src={group103} alt="logo" />
          <img className="image2" src={logo} alt="logo" />
        </div>
      )}

      {!remind_ok && (
        <form className="main" onSubmit={onSubmit}>
          <p className="header">Восстановление пароля</p>
          <label>
            Для восстановления введите почту на которую был создан аккаунт и мы
            вышлем вам ссылку по востановлению аккаунта
          </label>
          <div className="in">
            <InputRegister
              placeholder="Почта"
              type="email"
              name="email"
              onChange={changeHandler}
              autoComplete="off"
              isError={errorRemind}
            />
            {errorRemind && <div className="error-message">{errorRemind}</div>}
          </div>
          <ButtonBlue text="Восстановить" width="316px" type="submit" />
        </form>
      )}
      {remind_ok && (
        <div className="main2">
          <p className="header">Отлично!</p>
          <label>
            Мы уже отправили На вашу почту письмо с активацией вашего личного
            кабинета! Скоро увидемся!
          </label>
          <label></label>
          <img className="success" src={success} alt="success" />
        </div>
      )}

      <div className="search">
        <SmallMenu isVisible={isVisible} />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  /* position: relative; */

  /* display: flex;
  flex-direction: column;
  align-items: center; */
  .search {
    display: none;
  }
  .close {
    background: var(--blue-1);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    svg {
      width: 35px;
      height: 35px;
      color: white;
    }
  }
  .close2 {
    background: transparent;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 3;

    svg {
      width: 35px;
      height: 35px;
      color: black;
    }
  }
  .frame {
    width: 100%;
  }
  .main {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Cera Pro Medium", sans-serif;
    padding: 12px;
  }
  .main2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Cera Pro Medium", sans-serif;
    padding: 12px;
    position: fixed;

    z-index: 2;
  }
  .header {
    font-size: 24px;
    margin-bottom: 30px;
    z-index: 2;
  }
  label {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.5;
    z-index: 2;
    width: 316px;
  }
  .in {
    width: 316px;
    margin: 15px 0;
  }
  .success {
    position: absolute;
    left: 0px;
    bottom: 80px;
    width: 50%;
    max-width: 400px;
  }
  .error-message {
    color: var(--red-1);
    text-align: right;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .logo-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    .image1 {
      width: 90px;
    }
    .image2 {
      width: 166px;
    }
  }
  @media (min-width: 576px) {
    .frame {
      display: none;
    }
    .main {
      height: 80vh;
    }
    .close {
      display: none;
    }
    .close2 {
      display: none;
    }
    .search {
      display: block;
    }
    .logo-container {
      display: none;
    }
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default SmallRemindPage;
