import React from "react";
import styled from "styled-components";

const AboutLink = () => {
  return (
    <Wrapper>
      <p>
        Мы создавали наш маркетплейс ориентируясь на игроков и ежедневно
        консультируясь с опытными продавцами. Мы обращаем внимание на все
        тонкости при торговле игровыми ценностями, совершенствуем нашу систему,
        делаем её удобной и понятной для всех.
      </p>
      <p>
        На PayGame вы сможете заметить множество отличительных особенностей:
        визуально удобные фильтры; быстрая, дружелюбная и справедливая
        поддержка; автоматическая выдача товаров; мгновенные уведомления в
        telegram и множество других удобных мелочей, которые составляют общую
        картину того проекта, который вы хотите видеть. Мы прислушиваемся к
        пользователям, воплощаем их идеи и делаем проект удобнее, быстрее и
        надежнее.
      </p>
      <p>Добро пожаловать на PayGame!</p>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 25px 20px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #eef0f3;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 45%;
    padding: 30px 40px;
    border-radius: 0;
    p {
      margin-bottom: 10px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AboutLink;
