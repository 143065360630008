import React from "react";
import styled from "styled-components";

const FooterLine3 = () => {
  return (
    <Wrapper>
      <div className="line3-line reverse">
        <p>© {new Date().getFullYear()} ООО «Клаудмаркет»</p>
        <p>mail@mail.ru</p>
      </div>
      <div className="line3-line link">
        <p>Правила</p>
        <p>Агентский договор</p>
        <p>Соглашение</p>
        <p>Конфиденциальность</p>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-top: 20px;
  .line3-line {
    color: var(--gray-6);
    display: flex;
    flex-wrap: wrap;
    p {
      margin: 10px;
    }
  }
  .reverse {
    flex-direction: column-reverse;
  }
  .link {
    cursor: pointer;
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 980px;
    flex-direction: row;
    .reverse {
      flex-direction: row;
    }
  }
  @media (min-width: 1200px) {
    width: 1180px;
  }
  @media (min-width: 1400px) {
  }
`;
export default FooterLine3;
