import React from "react";
import styled from "styled-components";

const UserNavLink = () => {
  return (
    <Wrapper>
      <div className="nav-links">
        <button className="nav-link">Вопрос и ответ</button>
        <button className="nav-link">Поддержка</button>
        <button className="nav-link">Оплата</button>
        <button className="nav-link">О нас</button>
        <button className="nav-link">Блог и статьи</button>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  /* max-width: 1180px; */
  /* border: 1px solid gray; */
  .nav-link {
    padding: 0.5rem;
    margin: 0 0.5rem;
    background: transparent;
    border: none;
    font-size: 16px;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default UserNavLink;
