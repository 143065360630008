import React, { useState, useEffect } from "react";
import styled from "styled-components";
import VisaOne from "./VisaOne";
import VisaTwo from "./VisaTwo";
import VisaThree from "./VisaThree";

const VisaMain = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <Wrapper>
          <VisaThree />
          <VisaOne />
          <VisaTwo />
        </Wrapper>
      ) : (
        <Wrapper>
          <VisaOne />
          <VisaTwo />
          <VisaThree />
        </Wrapper>
      )}
    </>
  );
};
const Wrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  margin: 20px;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 980px;
    flex-direction: row;
  }
  @media (min-width: 1200px) {
    width: 1180px;
  }
  @media (min-width: 1400px) {
  }
`;
export default VisaMain;
