import React from "react";
import styled from "styled-components";
import avatar from "../../images/Ellipse 1.png";
import star from "../../images/Star 1.png";
import coin from "../../images/Ellipse 45.png";

const Offer = ({
  name,
  type,
  category,
  amount,
  userStars,
  userReviews,
  userName,
  userPrice,
}) => {
  function formatNumber(num) {
    return num.toLocaleString("ru-RU");
  }

  return (
    <Wrapper>
      <div className="line"></div>
      <div className="offer">
        <div className="offer-name">
          <p className="first-three-letters">{name.slice(0, 0)}</p>
          {name.slice(0)}
        </div>
        <div className="offer-info">
          <p>Тип: {type}</p>
          <p>Категория: {category}</p>
        </div>
        <div className="offer-amount">
          <p>Кол-во: {amount}</p>
        </div>
      </div>
      <div className="user-price">
        <div className="offer-amount-big">
          <p>{amount}</p>
        </div>
        <div className="user">
          <div className="avatar">
            <img src={avatar} alt="avatar" />
            <div className="circle"></div>
          </div>
          <div className="user-info">
            <div className="offer-stars">
              <img src={star} alt="star" />
              <p>
                {userStars} / {userReviews}
              </p>
            </div>
            <div className="offer-stars2">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <p>{userReviews}</p>
            </div>
            <div className="user-name">
              <p>{userName}</p>
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="offer-price">
            <p className="price" style={{ fontSize: "20px" }}>
              {formatNumber(userPrice)}
            </p>
            <img src={coin} alt="coin" />
          </div>
          <p className="price-text">СТОИМОСТЬ</p>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 18px 10px 12px 20px;
  position: relative;
  border-bottom: 1px solid #f2f3f6;
  border-top: none;
  transition: 0.7s;
  cursor: pointer;
  :hover {
    background: var(--gray-2);
    .line {
      background: var(--green-1);
    }
  }
  .offer-name {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .offer-info {
    display: flex;
    p {
      margin-right: 10px;
      color: var(--gray-1);
      font-size: 12px;
    }
  }
  .offer-amount {
    p {
      color: var(--gray-1);
      font-size: 12px;
    }
  }
  .offer-stars {
    display: flex;
    p {
      margin-left: 10px;
      font-size: 12px;
      color: var(--gray-1);
    }
  }
  .user {
    display: flex;
    margin-top: 10px;
  }
  .avatar {
    position: relative;
    .circle {
      position: absolute;
      width: 8px;
      height: 8px;
      background: var(--green-1);
      border-radius: 50%;
      right: 1px;
      bottom: 11px;
    }
  }
  .user-info {
    margin-left: 10px;
  }
  .user-name {
    font-size: 14px;
  }
  .offer-price {
    display: flex;
    img {
      margin-left: 10px;
    }
    .price {
      font-size: 20px;
    }
  }
  .user-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .price-text {
    font-size: 12px;
    color: var(--gray-1);
    text-align: end;
  }
  .line {
    width: 7px;
    height: 75%;
    position: absolute;
    bottom: 17px;
    left: 0;
    border-radius: 0px 20px 20px 0px;
    background: var(--blue-1);
    transition: 0.5s;
  }
  .offer-amount-big {
    display: none;
  }
  .offer-stars2 {
    display: none;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    padding: 18px 40px;
    :hover {
      .offer-name {
        .first-three-letters {
          color: black;
        }
      }
    }
    .offer-name {
      font-size: 20px;
      .first-three-letters {
        color: var(--blue-1);
        font-size: 20px;
        transition: 0.5s;
      }
    }
    .offer-info {
      p {
        font-size: 14px;
      }
    }
    .offer-amount {
      display: none;
    }
    .offer-amount-big {
      display: block;
      font-size: 20px;
    }

    .user-price {
      width: 50%;
      justify-content: flex-end;
      margin-left: 70px;
      .user {
        margin: 0px 70px;
      }
    }
    .offer-stars {
      display: none;
    }
    .offer-stars2 {
      display: flex;
      p {
        margin-left: 10px;
        font-size: 14px;
      }
    }
    .avatar {
      img {
        width: 42px;
        height: 42px;
      }
      .circle {
        width: 10px;
        height: 10px;
        right: 2px;
        bottom: 7px;
      }
    }
    .user-name {
      font-size: 18px;
    }
    .offer-price {
      .price {
        font-size: 24px;
      }
      img {
        width: 34px;
        height: 34px;
      }
    }
    .price-text {
      display: none;
    }
    .line {
      width: 7px;
      height: 34px;
      position: absolute;
      /* bottom: 17px; */
      top: 26px;
      left: 0;
      border-radius: 0px 20px 20px 0px;
      background: var(--blue-1);
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Offer;
