import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { currentUserHandler } from "../..//features/admin/adminSlice";

const User = ({ name, email, _id }) => {
  const dispatch = useDispatch();
  return (
    <Wrapper onClick={() => dispatch(currentUserHandler(_id))}>
      <div className="main-info">
        <p>{email}</p>
        <p>{name}</p>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-bottom: 1px solid var(--blue-0);
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    color: var(--blue-0);
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default User;
