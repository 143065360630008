import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";

const UserInfo = () => {
  const { allUsers, currentUser } = useSelector((store) => store.admin);
  const thisUser = _.find(allUsers, (user) => user._id === currentUser);
  // console.log(thisUser);

  return (
    <Wrapper>
      {/* <p>{thisUser.name}</p>
      <p>{thisUser.email}</p>
      <p>{moment(thisUser.createdAt).format("lll")}</p>
      <p>{moment(thisUser.updatedAt).format("lll")}</p> */}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 1rem;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default UserInfo;
