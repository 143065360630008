import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import CollectionBigMenu from "./CollectionBigMenu";

const CatalogBigMenu = () => {
  const { categories } = useSelector((store) => store.user);
  const rootCategories = _.filter(categories, (c) => c.parentId === null);
  return (
    <Wrapper>
      {rootCategories.map((category) => (
        <CollectionBigMenu
          key={category._id}
          category={category}
          categories={categories}
          level={0}
        />
      ))}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: none;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-start;
    flex-wrap: wrap;
    position: absolute;
    top: 110px;
    width: 980px;
    /* border: 1px solid gray; */
    height: 500px;
    background: white;
    overflow-y: auto;
    padding: 20px 0;
    z-index: 2;
    &::-webkit-scrollbar {
      display: none; // Скрывает скроллбар для Webkit-браузеров (например, Chrome и Safari)
    }
    -ms-overflow-style: none; // Скрывает скроллбар в IE и Edge
    scrollbar-width: none; // Скрывает скроллбар в Firefox
  }
  @media (min-width: 1200px) {
    width: 1180px;
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1400px) {
  }
`;
export default CatalogBigMenu;
