import React from "react";
import styled from "styled-components";
import logo from "../../images/Logo.png";
import ButtonCatalog from "./ButtonCatalog";
import Search from "./Search";
import ButtonSell from "./ButtonSell";

const UserNavBar2 = () => {
  return (
    <Wrapper>
      <img src={logo} alt="logo" />
      <ButtonCatalog />
      <div className="search">
        <Search />
      </div>
      <ButtonSell text="Продать +" />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid gray; */
  margin: 1rem 0;
  font-family: "Cera Pro Medium", sans-serif;

  .search {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  display: none;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: flex;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default UserNavBar2;
