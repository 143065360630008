import React from "react";
import styled from "styled-components";

const InputRegister = ({
  type,
  placeholder,
  value,
  name,
  onChange,
  autoComplete,
  isError,
}) => {
  return (
    <Wrapper isError={isError}>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        autoComplete={autoComplete}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  input {
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    padding: 13px 20px;
    /* background: var(--gray-2); */
    border: none;
    font-size: 100%;
    height: 50px;
    font-family: "Inter", sans-serif;
    border: 1px solid ${(props) => (props.isError ? "var(--red-1)" : "none")};
    background: ${(props) =>
      props.isError ? "var(--red-2)" : "var(--gray-2)"};
    :focus {
      /* border: 2px solid var(--blue-1); */
      border: 1px solid
        ${(props) => (props.isError ? "var(--red-1)" : "var(--blue-1)")};
      background: ${(props) => (props.isError ? "var(--red-2)" : "white")};
    }
    :focus-visible {
      outline: none;
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default InputRegister;
