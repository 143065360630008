import React from "react";
import styled from "styled-components";
import HeroMenuItemSmall from "./HeroMenuItemSmall";

const MySvgIcon1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="33"
    viewBox="0 0 34 33"
    fill="none"
  >
    <g clipPath="url(#clip0_269_231)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0647 11.0594C22.8341 12.2903 21.2042 13.1933 19.7468 13.755L19.7487 11.8786C19.7498 10.8291 18.6207 10.1667 17.705 10.6795L11.0043 14.4327C10.0961 14.9414 10.0624 16.2363 10.9428 16.7916L17.6356 21.0128C18.5493 21.589 19.7397 20.9331 19.7408 19.853L19.7422 18.4036C20.7864 18.7016 22.0943 19.2688 24.0645 20.4417C26.1843 21.7037 26.7286 23.6607 25.5515 25.5282C24.7392 26.1668 23.8333 26.7139 22.8425 27.1472C16.5553 29.8972 9.2292 27.0297 6.47924 20.7424C3.72928 14.4552 6.5968 7.12912 12.884 4.37916C17.1336 2.52045 21.8578 3.22808 25.3227 5.8234C26.7969 7.85334 25.8309 9.29258 24.0647 11.0594Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_269_231">
        <rect width="33" height="33" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

const data = [
  {
    name: "CloudMarket",
    description: "Более 30000 предложений",
    image: <MySvgIcon1 />,
  },
  {
    name: "Игры",
    description: "5 категорий (20006)",
    image: <MySvgIcon1 />,
  },
  {
    name: "Приложения и ПО",
    description: "5 категорий (20006)",
    image: <MySvgIcon1 />,
  },
  {
    name: "Цифровые товары",
    description: "5 категорий (20006)",
    image: <MySvgIcon1 />,
  },
  {
    name: "Социальные сети и сообщества",
    description: "5 категорий (20006)",
    image: <MySvgIcon1 />,
  },
  {
    name: "Подписки и сервисы",
    description: "5 категорий (20006)",
    image: <MySvgIcon1 />,
  },
  {
    name: "Сайты",
    description: "5 категорий (20006)",
    image: <MySvgIcon1 />,
  },
  {
    name: "Социальные сети и сообщества",
    description: "5 категорий (20006)",
    image: <MySvgIcon1 />,
  },
  {
    name: "Подписки и сервисы",
    description: "5 категорий (20006)",
    image: <MySvgIcon1 />,
  },
  {
    name: "Сайты",
    description: "5 категорий (20006)",
    image: <MySvgIcon1 />,
  },
];

const HeroMenuSmall = () => {
  return (
    <Wrapper>
      {data.map((d, i) => (
        <HeroMenuItemSmall
          key={i}
          name={d.name}
          description={d.description}
          image={d.image}
        />
      ))}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  overflow: auto;
  margin-bottom: 1rem;
  width: 100%;
  &::-webkit-scrollbar {
    display: none; // Скрывает скроллбар для Webkit-браузеров (например, Chrome и Safari)
  }
  -ms-overflow-style: none; // Скрывает скроллбар в IE и Edge
  scrollbar-width: none; // Скрывает скроллбар в Firefox
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    display: none;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default HeroMenuSmall;
