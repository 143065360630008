import React from "react";
import styled from "styled-components";

const VisaTwo = () => {
  return (
    <Wrapper>
      <div className="visa-text">
        Безопасность платежей обеспечивается с помощью Банка-эквайера
        (Газпромбанк (Акционерное Общество)), Тинькофф Банк, МодульБанк,
        функционирующих на основе современных протоколов и технологий,
        разработанных платежными системами МИР, Visa International и Mastercard
        Worldwide (3D-Secure: Verified by VISA, Mastercard SecureCode,
        MirAccept).{" "}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  /* border: 1px solid gray; */
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #eef0f3;
  width: 100%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  .visa-text {
    padding: 25px 20px;
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 45%;
    border-radius: 0;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default VisaTwo;
