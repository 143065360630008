import React from "react";
import styled from "styled-components";
import Review from "./Review";

const ReviewsMain = () => {
  return (
    <Wrapper>
      <p className="reviews-header">Отзывы о продовцах</p>
      <div className="reviews">
        <Review />
        <Review />
        <Review />
        <Review />
        <Review />
        <Review />
        <Review />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  margin: 40px;
  background: var(--green-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
  margin-bottom: 0;
  .reviews-header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .reviews {
    display: flex;
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none; // Скрывает скроллбар для Webkit-браузеров (например, Chrome и Safari)
    }
    -ms-overflow-style: none; // Скрывает скроллбар в IE и Edge
    scrollbar-width: none; // Скрывает скроллбар в Firefox
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .reviews-header {
      font-size: 40px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default ReviewsMain;
