import React from "react";
import styled from "styled-components";
import { GiBattleAxe } from "react-icons/gi";
import _ from "lodash";

const CollectionSmallMenu = ({ category, categories, level }) => {
  const children = _.filter(categories, (c) => c.parentId === category._id);
  const length = children.length;

  return (
    <Wrapper level={level} length={length}>
      <div className="line">
        {level === 0 && <GiBattleAxe />}
        <p
          className={
            level === 0
              ? "categoryName"
              : length > 0
              ? "categoryName2"
              : "categoryNameLast"
          }
        >
          {category.name}
        </p>
      </div>
      <div className="children">
        {children.map((child) => (
          <CollectionSmallMenu
            key={child._id}
            category={child}
            categories={categories}
            level={level + 1}
          />
        ))}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .categoryName {
    color: var(--blue-1);
    cursor: pointer;
  }
  .categoryName2 {
    opacity: 0.5;
    cursor: pointer;
  }
  .categoryNameLast {
    cursor: pointer;
  }
  .line {
    display: flex;
    align-items: center;
    width: 100%;
    svg {
      margin: 10px;
      font-size: 24px;
      color: var(--blue-1);
    }
  }
  .children {
    /* padding-left: 45px; */
    padding-left: ${(props) => (props.level === 0 ? "45px" : "0px")};
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default CollectionSmallMenu;
