import React from "react";
import styled from "styled-components";

const HeroMenuItem = ({ name, description, image }) => {
  return (
    <Wrapper>
      <div className="icon">
        <div className="icon-inner">{image}</div>
      </div>
      <div className="content">
        <p>{name}</p>
        <p className="description">{description}</p>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  transition: 1s;
  border-radius: 30px 0px 0px 30px;
  height: 88px;
  :hover svg path {
    stroke: black;
  }
  :hover {
    background: var(--green-1);
    color: black;
    .icon::before {
      border-top: 2px solid black;
      border-right: 2px solid black;
      border-bottom: 2px solid black;
      transform: rotate(45deg);
    }
  }
  .icon {
    width: 54px;
    height: 54px;
    flex-shrink: 0;
    background: rgba(236, 242, 255, 0.3);
    backdrop-filter: blur(39.1px);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    svg path {
      transition: 1s;
    }
  }
  .icon::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    /* width: 100%;
    height: 100%; */
    width: 54px;
    height: 54px;
    border: 2px solid transparent;
    transition: 1s;
  }
  .icon-inner {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    padding-right: 30px;
  }
  .description {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default HeroMenuItem;
