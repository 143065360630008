import React from "react";
import styled from "styled-components";
import { IoIosArrowForward } from "react-icons/io";

const NewsLine1Big = ({ name, timeToRead, date, background }) => {
  return (
    <Wrapper background={background}>
      <div className="news-tag">Tag</div>
      <div className="news-info">
        <div className="news-name">{name}</div>
        <div className="news-date">
          <div className="news-time">
            <p>{timeToRead}</p>
            <p>{date}</p>
          </div>
          <div className="news-more">
            <p>Подробнее</p>
            <IoIosArrowForward />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-position: center;
    border-radius: 32px;
    width: 50%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    :first-child {
      margin-right: 20px;
    }
    .news-tag {
      padding: 6px 10px;
      width: min-content;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.2);
      font-size: 14px;
    }
    .news-name {
      font-size: 23px;
      margin-bottom: 10px;
    }
    .news-date {
      display: flex;
      justify-content: space-between;
    }
    .news-time {
      display: flex;
      font-size: 14px;
      opacity: 0.8;
      p:first-child {
        margin-right: 15px;
      }
    }
    .news-more {
      display: flex;
      font-size: 16px;
      align-items: center;
      cursor: pointer;
      p:first-child {
        margin-right: 15px;
      }
    }
  }
  @media (min-width: 1200px) {
    .news-name {
      font-size: 25px;
    }
  }
  @media (min-width: 1400px) {
  }
`;
export default NewsLine1Big;
