import React, { useState, useEffect } from "react";
import styled from "styled-components";
import UserNavBar from "../user-components/navigation/UserNavBar";
import UserNavBar2 from "../user-components/navigation/UserNavBar2";
import HeroMain from "../user-components/hero/Hero-main";
import SmallSearch from "../user-components/navigation/SmallSearch";
import SmallMenu from "../user-components/navigation/SmallMenu";
import CatalogBigMenu from "../user-components/catalog/CatalogBigMenu";
import { getCatalog } from "../features/user/userSlise";
import { useSelector, useDispatch } from "react-redux";
import OffersMain from "../user-components/offers/Offers-main";
import AboutMain from "../user-components/about/Amout-main";
import VisaMain from "../user-components/visa/Visa-main";
import AboutUsMain from "../user-components/aboutUs/AmoutUs-main";
import NewsBigMain from "../user-components/news/NewsBig-main";
import NewsSmallMain from "../user-components/news/NewsSmall-main";
import ReviewsMain from "../user-components/reviews/Reviews-main";
import FooterMain from "../user-components/footer/Footer-main";
import RegisterModal from "../user-components/registerBig/RegisterModal";
import SuccessModal from "../user-components/registerBig/SuccessModal";

const HIDE_SCROLL_THRESHOLD = 60;

const LandingPage = () => {
  // const {} = useSelector((store) => store.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCatalog());
  }, []);

  const { isCatalogBig, isRegisterBig, remind_ok } = useSelector(
    (store) => store.user
  );

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      let currentScroll = window.scrollY;

      if (
        currentScroll > lastScrollTop &&
        currentScroll > HIDE_SCROLL_THRESHOLD
      ) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  return (
    <>
      <Wrapper>
        <SmallSearch />
        <div className="navigation">
          <UserNavBar />
          <UserNavBar2 />
        </div>
        {isCatalogBig && <CatalogBigMenu />}
        <HeroMain />
        <OffersMain />
        <NewsSmallMain />
        <AboutMain />
        <VisaMain />
        <AboutUsMain />
        <NewsBigMain />
        <ReviewsMain />
        <FooterMain />
        <SmallMenu isVisible={isVisible} />
      </Wrapper>
      {isRegisterBig && <RegisterModal />}
      {remind_ok && <SuccessModal />}
    </>
  );
};
const Wrapper = styled.div`
  /* max-width: 1180px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid green; */
  font-family: "Cera Pro Medium", sans-serif;

  .navigation {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default LandingPage;
