import React from "react";
import styled from "styled-components";

const HeroInfo = () => {
  return (
    <Wrapper>
      <p className="line1">Покупайте и</p>
      <p className="line1">продавайте подписки</p>
      <p className="line1">и золото за минуту</p>
      <p className="line2">Биржа для торговли между игроками</p>
      <p className="line3">Более 3000 предложений </p>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 50px;
  margin-left: 1rem;
  /* border: 1px solid white; */
  .line1 {
    font-size: 30px;
  }
  .line2 {
    font-size: 18px;
    margin-top: 0.7rem;
  }
  .line3 {
    font-size: 16px;
    margin-top: 0.7rem;
    opacity: 0.7;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    justify-content: end;
    margin-left: 2rem;
    margin-bottom: 2rem;
    .line1 {
      font-size: 30px;
    }
    .line2 {
      font-size: 18px;
    }
    .line3 {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) {
    .line1 {
      font-size: 45px;
    }
    .line2 {
      font-size: 20px;
    }
    .line3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1400px) {
    .line1 {
      font-size: 55px;
    }
    .line2 {
      font-size: 20px;
    }
    .line3 {
      font-size: 16px;
    }
  }
`;
export default HeroInfo;
