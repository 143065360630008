import React from "react";
import styled from "styled-components";
import { FiMenu } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { GrClose } from "react-icons/gr";
import { catalogBigHandler } from "../../features/user/userSlise";

const ButtonCatalog = () => {
  const dispatch = useDispatch();
  const { isCatalogBig } = useSelector((store) => store.user);

  return (
    <Wrapper onClick={() => dispatch(catalogBigHandler())}>
      {isCatalogBig && <GrClose />}
      {!isCatalogBig && <FiMenu />}
      <p>Каталог предложений</p>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 248px;
  height: 50px;
  padding: 13px 20px;
  background: var(--green-1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  margin-left: 1rem;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default ButtonCatalog;
