import React from "react";
import styled from "styled-components";
import { MdOutlineEmail } from "react-icons/md";
import { FiBell } from "react-icons/fi";
import coins from "../../images/Ellipse 45.png";
import avatar from "../../images/Ellipse 4.png";
import { IoIosArrowDown } from "react-icons/io";

const IsUserPanelBig = () => {
  return (
    <Wrapper>
      <div className="message">
        <MdOutlineEmail />
      </div>
      <div className="bell">
        <FiBell />
        <div className="circle-bell"></div>
      </div>
      <div className="border"></div>
      <div className="money">
        <p>0.50</p>
        <img src={coins} alt="coins" />
      </div>
      <div className="name">
        <p>John May</p>
      </div>
      <div className="avatar">
        <img src={avatar} alt="avatar" />
        <div className="circle-online"></div>
      </div>
      <div className="arrow">
        <IoIosArrowDown />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 358px;
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  svg {
    width: 24px;
    height: 24px;
    color: var(--gray-3);
    cursor: pointer;
  }
  .message {
  }
  .bell {
    position: relative;
    .circle-bell {
      position: absolute;
      border-radius: 50%;
      right: 3px;
      top: -3px;
      width: 10px;
      height: 11px;
      border: 1px solid white;
      background: var(--blue-1);
    }
  }
  .border {
    background: var(--gray-4);
    width: 2px;
    height: 24px;
  }
  .money {
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .name {
    display: flex;
    align-items: center;
  }
  .avatar {
    position: relative;
    .circle-online {
      position: absolute;
      border-radius: 50%;
      right: 0px;
      bottom: 5px;
      width: 10px;
      height: 10px;
      border: 1px solid white;
      background: var(--green-1);
    }
  }
  .arrow {
    svg {
      width: 24px;
      height: 24px;
      color: var(--black-1);
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default IsUserPanelBig;
