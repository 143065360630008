import React from "react";
import styled from "styled-components";
import AdminNavBar from "../../admin-components/navigation/AdminNavBar";
import AdminSideBar from "../../admin-components/navigation/AdminSideBar";
import User from "../../admin-components/Users/User";
import UserInfo from "../../admin-components/Users/UserInfo";
import { useDispatch, useSelector } from "react-redux";

const AdminUsersPage = () => {
  const { allUsers } = useSelector((store) => store.admin);

  return (
    <>
      <AdminNavBar />
      <AdminSideBar />
      <Wrapper>
        <p className="page-header">Пользователи</p>
        <div className="content">
          <div className="users">
            {allUsers.map((user) => (
              <User key={user._id} {...user} />
            ))}
          </div>
          <div className="user-info">
            <UserInfo />
          </div>
        </div>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  padding: 1rem;
  .content {
    display: flex;
  }
  .users {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    overflow: auto;
  }
  .user-info {
    border: 1px solid gray;
    width: 100%;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AdminUsersPage;
